import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import jwtDecode from "jwt-decode";
import { BaseComponent } from "../types/components";
import Cookies from "js-cookie";
import { useUpdateEffect } from "react-use";

export type AuthContextValue = {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  login: (identifier: string, password: string, token: string) => Promise<void>;
  setToken: (token: string) => void;
};

export const AuthContext = createContext<AuthContextValue | null>(null);

type AuthData = {
  exp?: number;
};
const loadAuthDataFromCookie = (): AuthData | undefined => {
  const jwt = Cookies.get("payload-token");

  if (jwt) {
    return jwtDecode<AuthData>(jwt);
  } else {
    return undefined;
  }
};

const storeAuthDataInCookie = (jwt: string): AuthData => {
  Cookies.set("payload-token", jwt, {
    path: "/",
  });
  return jwtDecode<AuthData>(jwt);
};

const deleteAuthDataCookie = (): void => {
  Cookies.remove("payload-token");
};

const checkAuthentication = (authData: AuthData | undefined): boolean => {
  return !!authData?.exp && authData.exp * 1000 > Date.now();
};

export const AuthProvider: BaseComponent = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    return loadAuthDataFromCookie();
  });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return checkAuthentication(authData);
  });

  useUpdateEffect(() => {
    const authenticated = checkAuthentication(authData);
    if (authenticated !== isAuthenticated) setIsAuthenticated(authenticated);
  }, [authData]);

  const providerValue: AuthContextValue = {
    isAuthenticated,
    login: async (identifier, password, token) => {
      const response = await fetch("/api/nauth/login", {
        method: "post",
        body: JSON.stringify({
          identifier,
          password,
          token,
        }),
        headers: { "Content-Type": "application/json" },
      });
      return response.json();
    },
    logout: async () => {
      deleteAuthDataCookie();
      setAuthData(undefined);
    },
    setToken: (newToken: string) => {
      const newAuthData = storeAuthDataInCookie(newToken);
      setAuthData(newAuthData);
    },
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};
