import { PageProps } from "../types";
import { SpSiteData } from "../providers/SiteDataProvider";

const parseParams = (query: {
  "0": string;
  "1": string;
  "2": string;
  "3"?: string;
  params: string[];
  isExporting?: boolean;
}) => {
  if (query["0"]) {
    const { "0": langShort, "1": slugOrEvent, "2": eventId } = query;
    return {
      langShort,
      slug: eventId ? eventId : slugOrEvent,
      isEventPage: !!eventId,
    };
  } else if (Array.isArray(query.params)) {
    let params = query.params;
    if (query.isExporting) params = params.slice(1);

    const [langShort, slugOrEvent, eventId] = params;
    return {
      langShort,
      slug: eventId ? eventId : slugOrEvent,
      isEventPage: !!eventId,
    };
  } else {
    return {
      langShort: undefined,
      slug: undefined,
      isEventPage: false,
    };
  }
};

if (!global.spSiteData) global.spSiteData = new Map<string, any>();
const siteDataCache = global.spSiteData;

const getSiteData = async (
  baseUrl: string,
  spName: string,
  langShort: string,
  allowCache: boolean
) => {
  const url = `${baseUrl}/data/${spName}/${langShort}`;
  if (allowCache && siteDataCache.has(url)) return siteDataCache.get(url);
  const data = await fetch(url, {
    headers: {
      "cache-control": "no-cache",
    },
  }).catch((err) => {
    console.error(`fetching ${url} failed`, err);
    throw err;
  });

  const retVal = await data.json();

  if (allowCache) siteDataCache.set(url, retVal);

  return retVal;
};

const getPageData = async (
  baseUrl: string,
  spName: string,
  langShort: string,
  slug: string,
  isProduction: boolean | undefined
) => {
  const url = isProduction
    ? `https://salespartner.messefrankfurt.com/sites/${spName}/page-data/${langShort}/${slug}.json`
    : `${baseUrl}/data/${spName}/${langShort}/${slug}`;

  //console.log(`fetching page data from ${url}`);

  const data = await fetch(url, {
    headers: {
      "cache-control": "no-cache",
    },
  }).catch((err) => {
    console.error(`fetching ${url} for pageData failed`, err);
    throw err;
  });

  if ((data?.status || 500) >= 400) {
    throw new Error(`${url} returned status ${data?.status}`);
  }

  return await data.json().catch((err) => {
    console.error(`parsing pageData for ${url} failed`, err);
    throw err;
  });
};

const getSiteFromRequest = (site: SpSiteData): SpSiteData => {
  return {
    baseUrl: site.baseUrl,
    apiUrl: site.apiUrl || site.baseUrl,
    spName: site.spName,
    defaultLanguage: site.defaultLanguage,
  };
};

const getSite = (context: any): SpSiteData => {
  if (typeof window !== "undefined") {
    return (
      window as unknown as {
        spSiteData: SpSiteData;
      }
    ).spSiteData;
  } else {
    if (context.req?.site) {
      return {
        ...getSiteFromRequest(context.req.site),
        isProduction: context.req.isProduction,
      };
    } else {
      const spName = context.query?.spName || context.req?.site.spName;

      if (context.query?.isExporting) {
        const prodDomain = context?.query?.prodDomain;

        return {
          baseUrl: `https://${prodDomain}`,
          apiUrl: `https://${prodDomain}`,
          spName,
          defaultLanguage: context.query.defaultLanguage,
          isProduction: context.req.isProduction,
        };
      } else {
        return {
          baseUrl: `https://${spName}.preview.salespartner.network`,
          apiUrl: `https://${spName}.preview.salespartner.network`,
          spName,
          defaultLanguage: context.query.defaultLanguage,
          isProduction: context.req.isProduction,
        };
      }
    }
  }
};
const getInitialProps = async (context): Promise<PageProps> => {
  const params = parseParams(context.query);
  // console.log('params', context?.query?.isExporting);

  const slug =
    (params?.isEventPage && !params.slug?.includes("event/")
      ? `event/${params.slug}`
      : params?.slug) || "home";

  const site = getSite(context);

  const langShort = params?.langShort || site.defaultLanguage;

  //const isEventPage = params?.isEventPage;
  let apiUrl = context.query?.isExporting
    ? "https://salespartner.messefrankfurt.com"
    : site.baseUrl || site.apiUrl;

  if (typeof window !== "undefined") apiUrl = "";

  let error: undefined | string = undefined;
  const [siteData, page] = await Promise.all([
    getSiteData(apiUrl, site.spName, langShort, false),
    getPageData(apiUrl, site.spName, langShort, slug, site.isProduction),
  ]).catch((e) => {
    console.error(e);
    error = typeof e === "string" ? e : e.message;
    return [undefined, undefined];
  });

  if (!siteData) {
    return {
      error,
    } as PageProps;
  }

  return {
    ...site,
    ...siteData,
    page,
    language: langShort,
    isProduction: context?.req?.isProduction,
  };
};

export default getInitialProps;
