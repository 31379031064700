import { Content } from '../types';
import { SiteContext } from '../types/SiteContext';

type TextUtilContext = Pick<
    SiteContext,
    'siteUrls' | 'baseUrl' | 'baseUrlNoLang'
>;

const replaceLinks = (
    text: string | undefined,
    { siteUrls, baseUrl, baseUrlNoLang }: TextUtilContext,
) => {
    if (!text) return '';

    for (const url of siteUrls) {
        const regexContent = 'https?:\\/\\/' + url;
        const regex = new RegExp(regexContent, 'gmi');

        text = text.replace(regex, ''); //baseUrlNoLang);
    }

    const externalRegex = /<a href="http/g;

    text = text.replace(
        externalRegex,
        '<a target="_blank"  rel="noopener noreferrer" class="a-link--external" href="http',
    );

    return text;
};

const TextUtil = (siteContext: TextUtilContext) => {
    return {
        replaceLinks,
        formatText: (content?: string | Content) => {
            if (content) {
                const inputText =
                    typeof content === 'string' ? content : content.text;

                if (inputText) {
                    return replaceLinks(inputText, siteContext);
                }
            }

            return '';
        },
    };
};

export default TextUtil;
